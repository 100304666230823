import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image-es5";
import Layout from "../components/Layout";
import Events from "../img/events.svg";
import Lobster from "../img/lobster.svg";
import Content, { HTMLContent } from "../components/Content";
import FsLightbox from "fslightbox-react";
import { motion } from "framer-motion";

export const EventPageTemplate = ({
	title,
	content,
	contentComponent,
	eventimages,
	redbox,
	video,
	image1,
	image2,
	image3,
	image4,
}) => {
	const PageContent = contentComponent || Content;
	const [toggler, setToggler] = useState(false);

	let tempArray = [];
	eventimages.images.map((pic, index) => {
		tempArray.push(pic.image.childImageSharp.fluid.src);
	});

	function showLightbox(pic) {
		tempArray.unshift(pic);
		//tempArray = newOrder;
		//var arr = tempArray.prototype.slice.call(index);
		//newOrder.unshift(arr);
		//console.log(newOrder);
		setToggler(!toggler);
	}

	return (
		<>
			<FsLightbox toggler={toggler} sources={tempArray} />

			<section className="section section--gradient">
				<div className="container">
					<div className="columns">
						<div className="column is-8 is-offset-2">
							<motion.div
								style={{ originY: 1 }}
								initial="hidden"
								animate="visible"
								variants={{
									hidden: {
										scale: 0.8,
										opacity: 0,
									},
									visible: {
										scale: 1,
										opacity: 1,
										transition: {
											delay: 0.8,
										},
									},
								}}
							>
								<div
									style={{
										display: "flex",
										height: "100px",
										lineHeight: "1",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
										padding: "0px",
									}}
								>
									<img
										src={Lobster}
										alt="Buckhorn Supper Club Events"
										className="mb0 svg-lobster-menu"
									/>
								</div>
							</motion.div>

							<motion.div
								style={{ originY: 1 }}
								initial="hidden"
								animate="visible"
								variants={{
									hidden: {
										scale: 0.8,
										opacity: 0,
									},
									visible: {
										scale: 1,
										opacity: 1,
										transition: {
											delay: 1,
										},
									},
								}}
							>
								<hr
									className="style-seven"
									style={{
										backgroundColor: "none !important",
										background: "none !important",
									}}
								/>
							</motion.div>
							<div className="">
								<motion.div
									style={{ originY: 1 }}
									initial="hidden"
									animate="visible"
									variants={{
										hidden: {
											scale: 0.8,
											opacity: 0,
										},
										visible: {
											scale: 1,
											opacity: 1,
											transition: {
												delay: 1.2,
											},
										},
									}}
								>
									<h2
										style={{ marginTop: "20px" }}
										className="title is-size-3 has-text-weight-bold is-bold-light red"
									>
										{title}
									</h2>
								</motion.div>
							</div>
							<motion.div
								style={{ originY: 1 }}
								initial="hidden"
								animate="visible"
								variants={{
									hidden: {
										scale: 0.8,
										opacity: 0,
									},
									visible: {
										scale: 1,
										opacity: 1,
										transition: {
											delay: 1.4,
										},
									},
								}}
							>
								<PageContent className="content" content={content} />
							</motion.div>
							<div className="columns is-8">
								<div
									className="column is-12-desktop is-12-tablet aos-init aos-animate"
									data-aos="fade"
								>
									<motion.div
										style={{ originY: 1 }}
										initial="hidden"
										animate="visible"
										variants={{
											hidden: {
												scale: 0.8,
												opacity: 0,
											},
											visible: {
												scale: 1,
												opacity: 1,
												transition: {
													delay: 1.6,
												},
											},
										}}
									>
										<div
											className="event-date-box photo2"
											dangerouslySetInnerHTML={{
												__html: redbox,
											}}
										></div>
									</motion.div>
								</div>
							</div>
							{video.length ? (
								<div className="columns is-8">
									<div
										className="column is-12-desktop is-12-tablet aos-init aos-animate"
										data-aos="fade"
									>
										<div
											className="video-container"
											dangerouslySetInnerHTML={{
												__html: video,
											}}
										></div>
									</div>
								</div>
							) : null}
							<div className="event-images">
								<main>
									{eventimages.images.map((pic, index) => (
										<article
											key={index}
											onClick={() =>
												showLightbox(pic.image.childImageSharp.fluid.src)
											}
										>
											<img
												key={index}
												src={pic.image.childImageSharp.fluid.src}
												alt={pic.text}
												className="mb0"
											/>
										</article>
									))}
								</main>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

EventPageTemplate.propTypes = {
	bannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	eventimages: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	redbox: PropTypes.string,
	video: PropTypes.string,
	contentComponent: PropTypes.func,
};

const EventPage = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout className="chair-bg">
			<BackgroundImage
				fluid={post.frontmatter.bannerimage.childImageSharp.fluid}
				className="full-width-image-container margin-top-0 bg-center mb0"
			>
				<div
					className="mx-auto relative"
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
						background:
							"linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
						height: "100%",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "100px 0",
					}}
				>
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 0.8,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 0.6,
								},
							},
						}}
					>
						<div
							className="has-text-weight-bold is-size-1"
							style={{
								backgroundColor: "rgb(166, 33, 3)",
								color: "white",
								padding: "1rem",
							}}
						>
							<div
								style={{
									display: "flex",
									lineHeight: "1",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									padding: "0px",
									height: "auto",
									position: "relative",
								}}
							>
								<img
									src={Events}
									alt="Buckhorn Supper Club Events"
									className="mb0 svg-logo-menu"
								/>
							</div>
						</div>
					</motion.div>
				</div>
			</BackgroundImage>
			<EventPageTemplate
				bannerimage={post.frontmatter.bannerimage}
				image1={post.frontmatter.image1}
				image2={post.frontmatter.image2}
				image3={post.frontmatter.image3}
				image4={post.frontmatter.image4}
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.html}
				redbox={post.frontmatter.redbox}
				video={post.frontmatter.video}
				eventimages={post.frontmatter.eventimages}
			/>
		</Layout>
	);
};

EventPage.propTypes = {
	data: PropTypes.object.isRequired,
};

export default EventPage;

export const eventPageQuery = graphql`
	query EventPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
				redbox
				video
				eventimages {
					images {
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						text
					}
				}
				bannerimage {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				image1 {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				image2 {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				image3 {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				image4 {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
